import React, { useContext, useEffect } from "react";
import styles from "./index.module.css";
import CheckIcon from "../../images/icons/check_icon.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { ApplicantStage } from "../../utils/applicationStage";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";

function DisbursedPage() {
  const navigate = useNavigate();
  const { loanAmount } = useContext(DataContext) as DataContextType;
  const location = useLocation();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage) {
          console.log(res?.data?.stage);

          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/enter-pan", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/enter-pan", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Digilocker:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Selfie:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Agreement:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.BankAccountVerified:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Mandate:
              // navigate("/disbursed-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              // navigate("/disbursed-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Disbursed:
              // navigate("/disbursed-page", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <img
            src={CheckIcon}
            alt=""
            style={{
              height: "4em",
              marginTop: "4rem",
            }}
          />
          <h1
            style={{
              color: "#D32028",
              fontSize: "2.3em",
              fontWeight: "normal",
            }}
          >
            Congratulations!
          </h1>
          <h2
            style={{
              fontSize: "1.6em",
              fontWeight: "normal",
            }}
          >
            Your loan is under disbursal.
          </h2>
          {/* <h3
            style={{
              fontSize: "2.3em",
              fontWeight: "bold",
            }}
          >
            ₹ {loanAmount}
          </h3> */}
          <div className={styles.holdOnTextContainer}>
            <p className={styles.holdOnHelperText}>
              If you have a problem, please contact us:
            </p>
            <p className={styles.holdOnHelperTextSmall}>hello@feemonk.com</p>
          </div>
          {/* <Button
            onPress={() => {
              // navigate("/sanction-letter");
              navigate("/select-emi");
            }}
            text={"Get sanction letter"}
          /> */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default DisbursedPage;
