import React from "react";
import {
  Container,
  List,
  ListItem,
  Button,
  Grid,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { Homestyle } from "./Style";
import { useNavigate } from "react-router-dom";

export default function Educationcard({application}) {
  const navigate = useNavigate();
  console.log(application,'application in edu')
  const {userId, applicationId, mobile} = application ? application : {}

  const body ={
  mobile,
  // course: studentCourse,
  // fees: courseFees,
  // studentName: studentName,
  // instituteName: instituteName,
  // studentEmail:studentEmail,
  userId,
  applicationId,
  channelId:2,
  flow:'apply-now'
}
const sendBody = btoa(JSON.stringify(body))

  return (
    <Homestyle>
      <div className="education-bg">
        <Container>
          <Typography gutterBottom className="Priority">
            Your education is our Priority
          </Typography>
          <Typography gutterBottom className="Crores">
            You can now avail Education loan
            <br /> up to <span className="text-cror"> 10 Lakhs</span>
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className="applybtn"
            style={{ fontSize: "1rem", width: "8em", height: "2em" }}
            onClick={() => {
              // alert("Contact us to apply for loan");
              // navigate(
              //   "/eyJtb2JpbGUiOiIxMjEyMTIxMjEyIiwiY291cnNlIjoiRGF0YSBTY2llbmNlIiwiZmVlcyI6MTAwMDAsInN0dWRlbnROYW1lIjoiU3dhcG5hZGVlcCIsImluc3RpdHV0ZU5hbWUiOiJVbmFjYWRlbXkiLCJlbWFpbCI6ImhlbGxvQHN3YXBuYWRlZXAuY29tIiwicmVkaXJlY3RVcmwiOiJodHRwOi8vbG9jYWxob3N0OjUxNzMvc3VjY2VzcyJ9"
              // );
              window.open(`https://staging-paylater.feemonk.com/pan-details/${sendBody}`, '_blank') 
            }}
          >
            Apply Now
          </Button>
        </Container>
      </div>
    </Homestyle>
  );
}
